@import "~theme/variables";

.container {
  position: relative;
  padding-bottom: 65%;
  border-radius: $border-radius-01;
  overflow: hidden;
}

.body {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
}
