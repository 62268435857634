@import '~theme/variables';

.title {
  padding-top: 5.2rem;
  position: static;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
}

.description {
  margin-bottom: 1.6rem;
}

.headerImageWrapper {
  position: relative;
  aspect-ratio: 4/3;
}

.header-image {
  margin-bottom: 2.5rem;
  box-shadow: $box-shadow-01;
  border-radius: $border-radius-03;
  filter: drop-shadow(0px 45px 27px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.09))
    drop-shadow(0px 5px 11px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));

  @include breakpoint(md) {
    margin-bottom: 4.2rem;
  }
}

.image {
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 1.6rem;
  box-shadow: $box-shadow-01;
  border-radius: $border-radius-03;
}
