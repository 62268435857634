@import '~theme/variables';

.footer {
  margin-top: 1.6rem;
}

.dark {
  position: relative;
  padding-bottom: 2.1rem;

  @include breakpoint(sm) {
    padding-bottom: 4.6rem;
  }

  @include breakpoint(md) {
    padding-bottom: 4.2rem;
  }

  &::before {
    content: '';
    width: 100%;
    left: 0;
    bottom: 0;
    position: absolute;
    height: 50%;
    max-height: 18.8rem;
    background: $color-neutral-01;
    z-index: $z-index-background;

    @include breakpoint(md) {
      max-height: 20.5rem;
    }
  }
}

.alternative {
  background-color: $color-neutral-01;
  border-top: 0.8rem solid $color-primary;
  margin-top: 24.8rem;

  @include breakpoint(sm) {
    margin-top: 16rem;
  }
}
